<template>
  <b-container fluid>
    <b-modal id="addShipping" :title="$t('customer_.shop_shipping')" hide-footer static centered>
      <div class="form-group chat-search-data m-0 row">
        <div class="col-sm-12">
          <input type="hidden" v-model="addShippingToken"/>
          {{ $t('customer_.shipping_name') }} <br>
          <input type="text" class="form-control round mb-4" :placeholder="$t('customer_.shipping_name')" v-model="addShippingName" >
          {{ $t('customer_.shipping_description') }} <br>
          <input type="text" class="form-control round mb-4" :placeholder="$t('customer_.shipping_description')" v-model="addShippingDescription" >
          {{ $t('customer_.shipping_price') }} <br>
          <div class="position-relative">
            <input type="number" class="form-control round mb-4" :placeholder="$t('customer_.shipping_price')" v-model="addShippingPrice" >
            <span class="serviceCurrency">{{ currency }}</span>
          </div>
          <div>
            <div class="custom-control custom-checkbox">
              <input type="checkbox" class="custom-control-input" id="special_price" v-model="specialPrice">
              <label class="custom-control-label" for="special_price">{{ $t('customer_.special_price') }}</label>
            </div>
            <div class="row p-0 m-0" v-show="specialPrice">
              <div class="col-sm-6 position-relative p-0 m-0">
                {{ $t('customer_.shipping_price_condition') }} <br>
                <input type="number" class="form-control round" :placeholder="$t('customer_.shipping_price')" v-model="addShippingPriceCondition" >
                <span class="serviceCurrency">{{ currency }}</span>
              </div>
              <div class="col-sm-6 position-relative p-0 m-0">
                {{ $t('customer_.shipping_price_condition_value') }} <br>
                <input type="number" class="form-control round" :placeholder="$t('customer_.shipping_price')" v-model="addShippingPriceConditionValue" >
                <span class="serviceCurrency">{{ currency }}</span>
              </div>
            </div>
          </div>

          <br>
          <b-button variant="primary" class="float-right" @click="addShipping">{{ $t('employee.save') }}</b-button>
        </div>
      </div>
    </b-modal>
    <b-row>
      <b-col sm="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('customer_.shop_shipping') }}</h4>
          </template>
          <template v-slot:headerAction>
            <div class="text-center">
              <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="primary"
                  dark
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ $t('customer_.add_shipping') }}
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  v-for="(item, index) in items"
                  :key="index"
                >
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            </div>

            <b-button variant="primary" @click="addNewShipping">{{ $t('customer_.add_shipping') }}</b-button>
          </template>
          <template v-slot:body>
            <div style="width: 100%">
              <v-text-field
                v-model="search"
                append-icon="ri-search-line"
                :label="$t('employees_list.search')"
                single-line
                hide-details
              ></v-text-field>
            </div>
            <v-data-table
              :headers="headers"
              :items="rowData"
              :search="search"
              :footer-props="{
              prevIcon: 'ri-arrow-left-line',
              nextIcon: 'ri-arrow-right-line'
            }"
            >
              <template v-slot:item.price="{ item }">
                {{ item.price }} {{ item.currency }}
              </template>
              <template v-slot:item.actions="{ item }">
                <button type="button" class="btn btn-default text-success" @click="editShipping(item)">
                  <i class="ri-pencil-line"></i> {{ $t('employees_list.edit') }}
                </button>
                <button type="button" class="btn btn-default text-danger" @click="removeData(item)">
                  <i class="ri-delete-bin-line"></i> {{ $t('employees_list.delete') }}
                </button>
              </template>
            </v-data-table>

          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import 'ag-grid-community/dist/styles/ag-grid.min.css'
import 'ag-grid-community/dist/styles/ag-theme-material.css'
import { AllCommunityModules } from '@ag-grid-enterprise/all-modules'
import { core } from '../../config/pluginInit'
import Axios from 'axios'
import { API } from '@/config/api.config'

export default {
  name: 'Shipping',
  mounted () {
    core.index()
    this.getShippings()
  },
  data () {
    return {
      apiToken: localStorage.getItem('api_token') || '',
      companyToken: localStorage.getItem('company_token') || '',
      columnDefs: null,
      rowData: [],
      modules: AllCommunityModules,
      headers: [
        { text: this.$t('employees_list.name'), align: 'start', sortable: true, value: 'name' },
        { text: this.$t('calendar.price'), value: 'price' },
        { text: this.$t('employees_list.actions'), value: 'actions' }
      ],
      search: '',
      addShippingName: '',
      addShippingDescription: '',
      addShippingPrice: '',
      addShippingPriceCondition: '',
      addShippingPriceConditionValue: '',
      currency: 'RON',
      specialPrice: false,
      addShippingToken: '',
      items: [
        { title: 'Click Me' },
        { title: 'Click Me' },
        { title: 'Click Me' },
        { title: 'Click Me 2' }
      ]
    }
  },
  methods: {
    addNewShipping () {
      this.addShippingToken = ''
      this.addShippingName = ''
      this.addShippingDescription = ''
      this.addShippingPrice = ''
      this.specialPrice = false
      this.addShippingPriceCondition = ''
      this.addShippingPriceConditionValue = ''
      this.$bvModal.show('addShipping')
    },
    editShipping (item) {
      this.addShippingToken = item.token
      this.addShippingName = item.name
      this.addShippingDescription = item.description
      this.addShippingPrice = item.price
      this.specialPrice = item.special_price
      this.currency = item.currency
      this.addShippingPriceCondition = item.price_condition
      this.addShippingPriceConditionValue = item.price_condition_value
      this.$bvModal.show('addShipping')
    },
    addShipping () {
      if (this.addShippingName === '') {
        core.showSnackbar('error', this.$t('customer_.shipping_name_missing'))
      } else if (this.addShippingPrice === '') {
        core.showSnackbar('error', this.$t('customer_.shipping_price_missing'))
      } else {
        const postData = {
          token: this.addShippingToken,
          name: this.addShippingName,
          description: this.addShippingDescription,
          price: this.addShippingPrice,
          special_price: this.specialPrice,
          currency: this.currency,
          price_condition: this.addShippingPriceCondition,
          price_condition_value: this.addShippingPriceConditionValue
        }
        Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
        Axios.post(API.API_ADD_SHIPPING, postData)
          .then((response) => {
            if (response.data.status === 'success') {
              core.showSnackbar('success', this.$t('customer_.shipping_succesfully_added'))
              this.getShippings()
            }
          })
          .catch((error) => {
            console.error(error.response)
            if (error.response && error.response.status === 401) {
              this.$router.push({ name: 'auth1.login' })
            }
          })
      }
    },
    getShippings () {
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      Axios.post(API.API_GET_SHIPPING)
        .then((response) => {
          if (response.data.status === 'success') {
            this.rowData = response.data.shipping
          }
        })
        .catch((error) => {
          console.error(error.response)
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    removeData (item) {
      const theToken = item.token
      this.$bvModal.msgBoxConfirm(this.$t('customer_.delete_shipping'))
        .then(value => {
          this.boxOne = value
          if (value === true) {
            const formData = {
              token: theToken
            }
            Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
            Axios.post(API.API_DELETE_SHIPPING, formData)
              .then((response) => {
                if (response.data.status === 'success') {
                  core.showSnackbar('success', this.$t('customer_.shipping_deleted'))
                  this.getShippings()
                }
              })
              .catch((error) => {
                console.error(error.response)
                if (error.response.status === 401) {
                  this.$router.push({ name: 'auth1.login' })
                }
              })
          }
        })
    }
  }
}
</script>
<style>
.serviceCurrency{
  position: absolute;
  right: 30px;
  bottom: 10px;
  font-weight: bold;
  color: #505050;
}
.v-menu{
  display: block;
}
</style>
